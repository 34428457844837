import "./index.css";
import SelectBox from "../SelectBox";
import translate from "../../translate";

function Header({ lang, setLang }) {
  const onSelect = (event) => {
    setLang(event.target.value);
  };

  return (
    <header className="App-header">
      <nav className="App-header__nav">
        <a href="http://kroviniuekspertai.lt"><div className="App-header__nav--img"></div></a>
        <ul className="App-header__nav--ul">
          <li className="App-header__nav--li">
            <a className="App-header__a" href="#about">
              {translate[lang].header.links.about}
            </a>
          </li>
          <div className="divider"></div>
          <li className="App-header__nav--li">
            <a className="App-header__a" href="#services">
              {translate[lang].header.links.service}
            </a>
          </li>
          <div className="divider"></div>
          <li className="App-header__nav--li">
            <a className="App-header__a" href="#team">
              {translate[lang].header.links.team}
            </a>
          </li>
          <div className="divider"></div>
          <li className="App-header__nav--li">
            <a className="App-header__a" href="#job">
              {translate[lang].header.links.job}
            </a>
          </li>
          <div className="divider"></div>
          <li className="App-header__nav--li">
            <a className="App-header__a" href="#contact">
              {translate[lang].header.links.contact}
            </a>
          </li>
        </ul>
        <SelectBox
          onChange={onSelect}
          options={[
            { value: "lt", children: "Lietuvių" },
            { value: "en", children: "English" },
          ]}
        />
      </nav>
    </header>
  );
}

export default Header;
