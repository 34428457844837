// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from "react";
import translate from "./translate";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status} = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/xwkwyzje"
        method="POST"
        className="App-main__contacts--form"
      >
        <input
          type="text"
          name="name"
          placeholder="Vardas, Pavardė / Name, Surname"
          required="required"
        ></input>
        <input
          type="tel"
          name="phone"
          placeholder="Telefono numeris / Phone number"
        ></input>
        <input
          type="email"
          name="email"
          placeholder="El. paštas / E-mail address"
          required="required"
        ></input>
        <textarea
          name="message_text"
          placeholder="Žinutė / Message"
          required="required"
        ></textarea>

        {status === "SUCCESS" ? (
          <p>Dėkojame! / Thanks!</p>
        ) : (
          <button className="App-main__contacts--button">✉</button>
        )}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
