import { Fragment, useState } from "react";
import "./index.css";
import Layout from "./components/Layout";
import List from "./components/List";
import Link from "./components/Link";
import translate from "./translate";
import Divider from "./components/Divider";
import Title from "./components/Title";
import MyForm from "./myform";
import Sidebar from "./Sidebar";

function App() {
  const [lang, setLang] = useState("lt");

  return (
    <div className="App">
      <Layout language={lang} setLanguage={setLang}>
        <div className="Burger" id="outer-container">
          <Sidebar
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          />
          <div id="page-wrap"></div>
        </div>
        <article className="App-main__picture">
          <section className="main-pic-div">
            <h1 className="App-main__title">KROVINIŲ EKSPERTAI</h1>
            <h2 className="App-main__title--second">
              {translate[lang].main.index.title}
            </h2>
            <Link classLink="App-main__a" link="#about">
              {translate[lang].main.index.button}
            </Link>
          </section>
        </article>
        <Divider classDivide="skew-cc-black" idDivide="about"></Divider>

        <article className="App-main__about">
          <Title title={translate[lang].main.about.title}></Title>
          <section className="App-main__about--box">
            <p className="App-main__section--paragraph">
              {translate[lang].main.about.textOne}
            </p>
            <div className="App-main__about--img-one"></div>
          </section>

          <section className="App-main__about--box">
            <p className="App-main__section--paragraph">
              {translate[lang].main.about.textTwo}
            </p>
            <div className="App-main__about--img-two"></div>
          </section>
        </article>

        <Divider classDivide="skew-cc-white" idDivide="services"></Divider>

        <article className="App-main__services">
          <article className="wrapper">
            <Title title={translate[lang].main.services.title}></Title>
        
              {translate[lang].main.services.servicesContent.map(
                (list, index) => (
                  <Fragment key={index}>
                    <List
                      listClass="App-main__services--delivery"
                      liElement={list.map((children) => ({ children }))}
                    />
                  </Fragment>
                )
              )}
              <div className="App-main__team--span-note">
                {translate[lang].main.services.additional}
              </div>
     
          </article>
        </article>

        <div className="divider-square">
          <div className="divider-white-line" id="team"></div>
        </div>

        <article className="App-main__team-box">
          <div className="wrapper d-flex">
            <div className="blurred-box">
              <section className="App-main__job">
                <Title title={translate[lang].main.team.title}></Title>
                <p className="App-main__team--paragraph">
                  {translate[lang].main.team.teamContent}
                </p>
                <span className="App-main__team--span">
                  {translate[lang].main.team.additional}
                </span>
              </section>
              <div className="divider-square-transparent">
                <div className="divider-white-line" id="job"></div>
              </div>

              <section className="App-main__job">
                <Title title={translate[lang].main.career.title}></Title>
                <p className="App-main__team--paragraph">
                  {translate[lang].main.career.careerContent}
                </p>
                <span className="App-main__team--span">
                  {translate[lang].main.career.additional}{" "}
                  <Link
                    classLink="job-link"
                    link="mailto:info@kroviniuekspertai.lt"
                  >
                    info@kroviniuekspertai.lt
                  </Link>
                  .
                </span>
              </section>
            </div>
          </div>
        </article>

        <article className="App-main__contacts" id="contact">
          <Title title={translate[lang].main.contacts.title}></Title>
          <section className="App-main__contacts--box">
            <section className="App-main__contacts--icons">
              <Link link="tel:866739763">
                <i className="fas fa-mobile-alt icon"></i>+37066739763
              </Link>
              <Link link="mailto:info@kroviniuekspertai.lt">
                <i className="fas fa-at icon"></i>info@kroviniuekspertai.lt
              </Link>
            </section>
            <MyForm></MyForm>
          </section>
        </article>
      </Layout>
    </div>
  );
}

export default App;
