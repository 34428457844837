import "./index.css";

function Divider({classDivide = "", idDivide = ""}) {
  return (
    <div>
      <div className={classDivide} id={idDivide}></div>
      <div className="divider-square"></div>
    </div>
  );
}

export default Divider;
