import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import "./Sidebar.css";

export default props => {
  return (
    <Menu className="burger">
      <a className="menu-item" href="#about">
        Apie mus / Home
      </a>
      <a className="menu-item" href="#services">
        Paslaugos / Services
      </a>
      <a className="menu-item" href="#team">
        Komanda / Team
      </a>
      <a className="menu-item" href="#job">
        Karjera / Career
      </a>
      <a className="menu-item" href="#contact">
        Kontaktai / Contact
      </a>
    </Menu>
  );
};