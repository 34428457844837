const translate = {
  //ENGLISH
  en: {
    header: {
      links: {
        about: "About us",
        service: "Services",
        team: "Team",
        job: "Career",
        contact: "Contact",
      },
    },
    main: {
      index: {
        title: "Convenient and fast freight transportation services",
        button: "MORE",
      },
      about: {
        title: "About us",
        textOne:
          "CARGO EXPERTS is a young professional company working in Lithuania and Europe whose task is to offer the best way of cargo transportation to each customer, taking into account individual wishes. Once we have found the most optimal way to fulfill your request, we will present you an offer for transportation and provide a guarantee of quality work.",
        textTwo:
          "Practical and fast transport will ensure safe delivery on time. No problem if you do not know where to start when transporting cargo. CARGO EXPERTS will advise everything from the ground up. We offer reliable freight services, consultations, work with reliable partners, using only the best experience. Prices and quality ratio - with us!",
      },
      services: {
        title: "Services",
        servicesContent: [
          [
            "Cargo transportation by spacious tent minibuses: from Lithuania to Europe, from Europe to Lithuania and within Europe.",
            "Freight forwarding in cooperation with reliable Lithuanian and foreign partners: route planning, carrier selection, cargo monitoring, document processing.",
            "Moving service.",
            "Consultancy in the field of cargo transportation and related document management issues.",
            "Fast delivery time in Europe and Lithuania.",
            "Services for both companies and individuals",
            "When providing services, we do everything with quality and protect your cargo."
          ],
        ],
        additional:
          "The price of cargo transportation depends on the size of the cargo and the country to which the cargo is transported. Please contact our staff for more detailed information.",
      },
      team: {
        title: "Team",
        teamContent:
          "TEAM is the most important part of the company. CARGO EXPERTS is a young, innovative, professional team that strives to fulfill your requests as smoothly as possible on a daily basis. We care about the well-being of our employees, so we strive to ensure a friendly environment in the company, social guarantees, career and personal development opportunities. We know – by providing the best conditions for employees, we will ensure quality services for you.",
        additional: "CARGO EXPERTS especially value their employees!",
      },
      career: {
        title: "Career",
        careerContent:
          "CARGO EXPERTS accept qualified, talented, curious employees whose most important goal is the best results. We offer a flexible work schedule, international relations and a motivational system. If you are motivated to work in our team, join us!",
        additional: "Contact us ",
      },
      contacts: {
        title: "Contact us:",
        nameCompany: "Company name *",
        phone: "Phone number *",
        email: "E-mail address *",
        message: "Your message *",
        submitButton: "Send",
        nameError: "Your or your company's name is required",
        emailError: "A valid email address is required",
      }
    },
    footer: {
      content: "© Krovinių ekspertai. All rights reserved, 2021.",
    },
  },
  //LITHUANIAN
  lt: {
    header: {
      links: {
        about: "Apie mus",
        service: "Paslaugos",
        team: "Komanda",
        job: "Karjera",
        contact: "Kontaktai",
      },
    },
    main: {
      index: {
        title: "Patogios ir greitos krovinių gabenimo paslaugos",
        button: "PLAČIAU",
      },
      about: {
        title: "Apie mus",
        textOne:
          "KROVINIŲ EKSPERTAI – jauna profesionalų įmonė, dirbanti Lietuvoje ir Europoje, kurios užduotis yra pasiūlyti geriausią krovinių gabenimo būdą kiekvienam klientui, atsižvelgiant į individualius norus. Suradę optimaliausią būdą įvykdyti Jūsų užklausą, pateiksime pasiūlymą transportavimui ir suteiksime garantiją dėl kokybiško darbų atlikimo.",
        textTwo:
          "Praktiškas, greitas transportas užtikrins saugų pristatymą laiku. Ne problema, jeigu nežinote, nuo ko pradėti transportuojant krovinius. KROVINIŲ EKSPERTAI patarts viską nuo pagrindų. Mes siūlome patikimas krovinių gabenimo paslaugas, konsultacijas, bendradarbiajame su patikimais partneriais, pasinaudodami tik geriausia patirtimi. Kainos ir kokybės santykis – pas mus!",
      },
      services: {
        title: "Paslaugos",
        servicesContent: [
          [
            "Krovinių pervežimas erdviais tentiniais mikroautobusais: iš Lietuvos į Europą, iš Europos į Lietuvą bei Europos viduje.",
            "Krovinių ekspedijavimas bendradarbiaujant su patikimais Lietuvos ir užsienio partneriais: maršruto sudarymas, vežėjo parinkimas, krovinio stebėjimas, dokumentų įforminimas.",
            "Perkraustymo paslaugos.",
            "Konsultacija krovinių transportavimo, su juo susijusių dokumentų tvarkymo klausimais.",
            "Greitas krovinių pristatymo terminas Europoje, Lietuvoje.",
            "Paslaugos teikiamos įmonėms ir fiziniams asmenims.",
            "Teikiant paslaugas viską atliekame kokybiškai ir saugome Jūsų krovinį."
          ],
        ],
        additional:
          "Krovinių gabenimo kaina priklauso nuo krovinio dydžio, valstybės, į kurią gabenamas krovinys. Dėl tikslesnės informacijos prašome kreiptis į mūsų darbuotojus.",
      },
      team: {
        title: "Komanda",
        teamContent:
          "KOMANDA – svarbiausia įmonės dalis. KROVINIŲ EKSPERTAI – tai jaunas, inovatyvus, profesionalus kolektyvas, kasdien besistengiantis įvykdyti Jūsų užklausas kiek įmanoma sklandžiau. Mums rūpi mūsų darbuotojų gerovė, todėl stengiamės užtikrinti draugišką aplinką įmonėje, socialines garantijas, suteikiame karjeros ir asmeninio tobulėjimo galimybes. Žinome, kad suteikdami geriausias sąlygas darbuotojams, užtikrinsime kokybiškas paslaugas Jums.",
        additional: "KROVINIŲ EKSPERTAI ypač vertina savo darbuotojus!",
      },
      career: {
        title: "Karjera",
        careerContent:
          "KROVINIŲ EKSPERTAI priima kvalifikuotus, talentingus, žingeidžius darbuotojus, kurių svarbiausias siekis – geriausi rezultatai. Siūlome lankstų darbo grafiką, tarptautinius ryšius ir motyvacinę sistemą. Jeigu esi motyvuotas dirbti mūsų komandoje, prisijunk!",
        additional: "Susisiek ",
      },
      contacts: {
        title: "Susisiekite su mumis:",
        nameCompany: "Įmonės pavadinimas *",
        phone: "Telefono numeris *",
        email: "El. paštas *",
        message: "Jūsų žinutė *",
        submitButton: "Siųsti",
        nameError: "Reikalingas Jūsų arba įmonės vardas",
        emailError: "Reikalingas galiojantis el.pašto adresas",
      }
    },
    footer: {
      content: "© Krovinių ekspertai. Visos teisės saugomos, 2021.",
    },
  },
};

export default translate;
